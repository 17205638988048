import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { Translate } from 'react-i18nify';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../Redux';

interface INavigationButtonProps {
    icon: string,
    buttonText: string,
    small?: boolean,
    showCoachMark?: boolean,
    navigationPath?: string,
    isActionButton?: boolean,
    openInNewWindow?: boolean,
    callback?: Function
}

export const NavigationButton = (props: INavigationButtonProps) => {
    const { icon, buttonText, small, showCoachMark, navigationPath, openInNewWindow, callback, isActionButton } = { ...props }

    const navigate = useNavigate();
    const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
    let resolved = useResolvedPath(navigationPath ?? "");
    let isPathMatch = useMatch({ path: resolved && resolved.pathname ? resolved.pathname : "", end: true });
    const [isCoackMarkVisible, setIsCoackMarkVisible] = useState(showCoachMark);

    useEffect(() => {
        setIsCoackMarkVisible(showCoachMark);
    }, [showCoachMark])

    const onLinkClick = () => {
        if (callback) {
            callback();
        }

        if (!isActionButton) {
            if (openInNewWindow) {
                window.open(navigationPath, '_blank');
            }
            else {
                navigate(navigationPath as string);
            }
        }
    }

    return (<>
        {
            <div className={`group relative flex justify-center items-center w-12 h-12 lg:h-9 text-center select-none flex-row space-x-1 lg:w-full lg:text-start text-sm lg:justify-start lg:items-center lg:space-x-2 lg:cursor-pointer transition-all
                            ${isPathMatch ? "bg-primary text-white top-0 rounded-t-full lg:hidden" : " text-primary"}
                            hover:font-semibold hover:text-base`}
                onClick={onLinkClick}>
                <Icon iconName={icon} className={`text-base lg:text-lg ${isPathMatch ? "font-normal animate-bounce-active" : "font-light"} ${small && 'group-hover:font-bold group-hover:text-xl'}`}></Icon>
                <span className={`truncate ${small ? 'hidden' : 'flex'} select-none group-hover:drop-shadow-sm`}><Translate value={buttonText} /></span>
                {
                    isCoackMarkVisible &&
                    <span className={`absolute top-0 lg:relative flex h-3 w-3 items-center first-letter
                                    ${isNavigationPanelOpen ? 'left-5 ' : 'left-1'}`}>
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                    </span>
                }
            </div>
        }
    </>);
}