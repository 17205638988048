import { useEffect, useState } from 'react';
import { setLocale, translate, Translate } from 'react-i18nify';
import { useAppDispatch, useAppSelector, useUpdateUserLanguageMutation, setCalendarStrings, setUserProfile } from '../../Redux'
import { IUserProfile } from '../../Models';

export const LanguageSwitch = () => {
    const dispatch = useAppDispatch();
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const [langShort, setLangShort] = useState<string>('EN');
    const [selectedLanguage, setSelectedLanguage] = useState<number | undefined>(userProfile?.preferredLanguage);
    const [updateLanguage] = useUpdateUserLanguageMutation();

    const swithLanguage = () => {
        let lcid = selectedLanguage === 1033 ? 1031 : 1033;
        dispatch(setCalendarStrings(lcid === 1033));
        setSelectedLanguage(lcid);
        setLocale(`Lang_${lcid}`, true);
        updateLanguage(lcid);
        dispatch(setUserProfile({...userProfile as IUserProfile, preferredLanguage: lcid}));
        setLangShort(selectedLanguage === 1033 ? 'EN' : 'DE');
    }

    useEffect(() => {
        setSelectedLanguage(userProfile?.preferredLanguage);
        setLangShort(userProfile?.preferredLanguage === 1033 ? 'EN' : 'DE');
        setLocale(`Lang_${userProfile?.preferredLanguage}`, true);
    }, [userProfile, userProfile?.preferredLanguage])

    return (<>
        {
            selectedLanguage &&
            (
                <div onClick={swithLanguage} className='text-text text-tertiary lg:text-primary text-xs cursor-pointer'>
                    <label>{langShort}</label>
                    {
                        selectedLanguage === 1033 ?
                            (
                                <svg id="flag-icons-gb" viewBox="0 0 640 480" className="h-3">
                                    <path fill="#012169" d="M0 0h640v480H0z" />
                                    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
                                    <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
                                    <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
                                    <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
                                </svg>
                            )
                            : (
                                <svg id="flag-icons-de" viewBox="0 0 640 480" className="h-3">
                                    <path fill="#ffce00" d="M0 320h640v160H0z" />
                                    <path fill="#000" d="M0 0h640v160H0z" />
                                    <path fill="#d00" d="M0 160h640v160H0z" />
                                </svg>
                            )
                    }
                </div>
            )
        }
    </>);
}