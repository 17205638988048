import { onFormatDate, addDateTimeOffset } from '../../Utils'
import { DatePicker, DayOfWeek, TextField, Dropdown, IDropdownOption, ICalendarProps, } from "@fluentui/react";
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { useState, FormEvent, SyntheticEvent, useEffect, useRef } from "react";
import { useAppSelector } from '../../Redux/hooks'
import { Translate, translate } from "react-i18nify";
import { ITimeEntry, IActivityTemplate, IPublicHoliday } from "../../Models";
import { ActivityTemplatesSelectComponent, ConfirmDialog, Divider, IconButton } from '..';
import { useBoolean } from '@fluentui/react-hooks';

interface ITimeEntryEditProps {
    timeEntry: ITimeEntry,
    onSave: Function,
    onClose?: Function,
    refreshTimeRecords: Function,
    updateWorkpackageCallback?: Function
}

export const TimeEntryEditMode = (props: ITimeEntryEditProps) => {
    const placeholdersAndLabels = {
        search: translate('App_Forms_Select_Activity_Template'),
        description: translate('App_Forms_Description')
    };

    const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);
    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const publicHolidays = useAppSelector((state) => state.publicHoliday.publicHolidays);
    const activityTemplates: IActivityTemplate[] = useAppSelector((state) => state.activityTemplates.activityTemplatesList);

    const targetContainer = useRef<HTMLDivElement>(null);
    const [isCoachmarkVisible, { setFalse: hideCoachmark, setTrue: showCoachmark }] = useBoolean(false);
    const [isTimeDirty, setIsTimeDirty] = useState<boolean>(false);
    const [isDialogHidden, setIsDialogHidden] = useState<boolean>(true);
    const [isDatePublicHoliday, setIsDatePublicHoliday] = useState<boolean>(false);
    const [currentPublicHoliday, setCurrentPublicHoliday] = useState<IPublicHoliday | null>();
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [comment, setComment] = useState<string | undefined>(props.timeEntry.comment ?? '');
    const [hours, setHours] = useState<string | undefined>(String(Math.floor(props.timeEntry.estimatedLength / 60)));
    const [minutes, setMinutes] = useState<string | undefined>(String(props.timeEntry.estimatedLength % 60));
    const [timeEntryDate, setTimeEntryDate] = useState<Date | undefined>(new Date(props.timeEntry.on));
    const [activityTemplateId, setActivityTemplateId] = useState<string>(props.timeEntry.skills ? props.timeEntry.skills : '');

    useEffect(() => {
        let newDate = new Date(props.timeEntry.on);
        setTimeEntryDate(newDate);

        var holidays = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == newDate.toDateString() });
        if (holidays.length > 0) {
            setIsDatePublicHoliday(true);
            setCurrentPublicHoliday(holidays[0])
        } else {
            setIsDatePublicHoliday(false);
            setCurrentPublicHoliday(null)
        }

        return (() => {

        });
    }, [props.timeEntry.on]);

    const onCommentChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setComment(newValue);

        if (activityTemplateId) {
            const bookingComment = activityTemplates.find(s => s.id == Number(activityTemplateId));
            if (!newValue || (bookingComment && bookingComment.description && newValue && newValue.indexOf(bookingComment.description as string) < 0)) {
                setActivityTemplateId('');
            }
        }
    };

    const onHoursChange = (event: SyntheticEvent<HTMLElement>, value?: string): void => {
        setHours(value);
        setIsTimeDirty(true);
    };

    const onMinutesChange = (event: SyntheticEvent<HTMLElement>, value?: string): void => {
        setMinutes(value);
        setIsTimeDirty(true);
    };

    const validateMinutesInput = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
        let numericValue = Number(value);
        var roundedNumber = (Math.round(numericValue / 15) * 15);
        return String(roundedNumber <= 45 ? roundedNumber : 45);
    };

    const onSelectDate = (date: Date | null | undefined) => {
        if (date) {
            setTimeEntryDate(date);

            var holidays = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == date?.toDateString() });
            if (holidays.length > 0) {
                setIsDatePublicHoliday(true);
                setCurrentPublicHoliday(holidays[0])
            } else {
                setIsDatePublicHoliday(false);
                setCurrentPublicHoliday(null)
            }
        }
    }

    const onSaveClick = () => {
        if (isDatePublicHoliday) {
            setIsDialogHidden(false);
        }
        else {
            saveTimeEntry();
        }
    }

    const saveTimeEntry = async () => {
        setIsDialogHidden(true);
        setIsSaveDisabled(true);
        const timeRecordLength: number = (Number(hours) * 60) + Number(minutes);

        let result: ITimeEntry = { ...props.timeEntry };
        result.comment = comment;
        result.actualLength = timeRecordLength;
        result.estimatedLength = timeRecordLength;
        result.state = 2;
        result.on = addDateTimeOffset(timeEntryDate as Date);
        result.skills = activityTemplateId;

        var subtractHours = true;
        if (props.timeEntry.id) {
            result.id = props.timeEntry.id;
            subtractHours = timeRecordLength > props.timeEntry.estimatedLength;
        }

        try {
            await props.onSave(result);
            props.updateWorkpackageCallback && props.updateWorkpackageCallback(result.estimatedLength, subtractHours);
            props.refreshTimeRecords();
            setComment('');
            setHours('8');
            setMinutes('0');
            setActivityTemplateId('');
        }
        catch (e) {
            console.error(e);
        }

        setIsSaveDisabled(false);
        setIsTimeDirty(false);
    }

    const closeTimeEntry = () => {
        if (props.onClose) {
            props.onClose();
            setIsTimeDirty(false);
        }
    }

    const onCommentSelectionChange = (selected: IActivityTemplate): void => {
        let newComment = comment;
        setActivityTemplateId(`${selected.id}`);
        newComment = (`${newComment}\n${selected?.description}`).trim();
        if (!isTimeDirty) {
            if (selected.defaultBookedTime > 0) {
                setHours(String(Math.floor(selected.defaultBookedTime / 60)));
                setMinutes(String(selected.defaultBookedTime % 60));
                showCoachmark();
                setTimeout(() => hideCoachmark(), 1000);
            } else {
                setHours('8');
                setMinutes('0');
            }
        }

        setComment(newComment);
    }

    const calendarProps: ICalendarProps = {
        navigationIcons: {
            leftNavigation: undefined,
            rightNavigation: undefined
        },
        calendarDayProps: {
            customDayCellRef: (element, date, classNames) => {
                if (element) {
                    var holidays = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == date.toDateString() })
                    if (holidays.length > 0) {
                        element.classList.add('dayMarker');
                        element.title = holidays[0].localName;
                    } else {
                        if (element.classList.contains('dayMarker')) {
                            element.classList.remove('dayMarker');
                            element.title = '';
                        }
                    }
                }
            }
        }
    };

    return (<>
        <div className="flex flex-col">
            {
                isDatePublicHoliday &&
                <Divider translatedText={<Translate value="App_Info_PublicHoliday" name={currentPublicHoliday?.localName as string} />} isWhite />
            }
            <div className='flex flex-row items-center lg:space-x-2 h-14'>
                <div className="hidden lg:w-1/5 lg:block z-10">
                    <label htmlFor="activityTemplatesSelect" className='text-sm font-semibold text-primary truncate'><Translate value="App_Forms_Select_Activity_Template" /></label>
                    <div id="activityTemplatesSelect">
                        <ActivityTemplatesSelectComponent onChange={onCommentSelectionChange} selectedActivityTemplateId={activityTemplateId} />
                    </div>
                </div>
                <div className={`w-1/4 lg:w-1/6 block`} ref={targetContainer}>
                    <label htmlFor="hoursSelect" className='text-sm font-semibold text-primary truncate'><Translate value="App_Label_Hours" /></label>
                    <div id="hoursSelect">
                        <SpinButton value={hours} min={0} max={400} step={1} onChange={onHoursChange} />
                    </div>
                </div>
                <div className={`w-1/4 lg:w-1/6 pl-1 lg:pl-0`}>
                    {isCoachmarkVisible && (
                        <span className="absolute flex h-8 w-8 items-center z-50">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-60"></span>
                        </span>)}
                    <label htmlFor="minutesSelect" className='text-sm font-semibold text-primary truncate'><Translate value="App_Label_Minutes" /></label>
                    <div id="minutesSelect">
                        <SpinButton value={minutes} min={0} max={45} step={15} onValidate={validateMinutesInput} onChange={onMinutesChange} />
                    </div>
                </div>
                <div className={`w-1/4 lg:w-1/5 block mt-[5px] pl-1 lg:pl-0`}>
                    <label htmlFor='dateSelect' className='text-sm font-semibold text-primary truncate'><Translate value="App_Label_On" /></label>
                    <div id="dateSelect">
                        <DatePicker
                            isMonthPickerVisible={false}
                            onSelectDate={onSelectDate}
                            minDate={dateRange[0] as Date}
                            maxDate={dateRange[dateRange.length - 1] as Date}
                            value={timeEntryDate as Date}
                            formatDate={onFormatDate}
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={calendarStrings}
                            showGoToToday={false}
                            calendarProps={calendarProps}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row space-y-1 mt-1">
            <div className="w-full flex lg:hidden z-10">
                <ActivityTemplatesSelectComponent onChange={onCommentSelectionChange} selectedActivityTemplateId={activityTemplateId} />
            </div>
            <div className='w-full lg:w-11/12'>
                <TextField placeholder={placeholdersAndLabels.description} value={comment} multiline rows={2} resizable={false} onChange={onCommentChange}></TextField>
            </div>
            <div className={`w-full flex flex-col justify-center lg:justify-end items-center space-y-1 lg:w-1/12`}>
                <button type="button" className="rounded-sm bg-primary px-2.5 py-1 w-11/12 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary" onClick={onSaveClick} disabled={isSaveDisabled}>
                    <Translate value="App_Button_Save" />
                </button>
                {props.timeEntry.id && <button type="button" className="w-11/12 rounded-sm bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary" onClick={closeTimeEntry}>
                    <Translate value="App_Button_Close" />
                </button>}
            </div>
        </div>

        <ConfirmDialog isHidden={isDialogHidden}
            infoTitle='App_Info_Confirm_Booking_PublicHoliday_Title'
            infoText='App_Info_Confirm_Booking_PublicHoliday'
            icon='Vacation'
            onConfirmClick={saveTimeEntry}
            onDismissClick={() => setIsDialogHidden(true)} />
    </>);
}

export default TimeEntryEditMode;