import { DateRangeType } from '@fluentui/date-time-utilities/lib/dateValues/dateValues'
import { useState, useEffect } from 'react';
import { CalendarFormatButton } from '..';
import { useAppDispatch, useAppSelector, updateDateRangeType, useUpdateBookingFormatMutation } from '../../Redux';

export const DateRangeTypeSelector = () => {
    const dispatch = useAppDispatch();
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);

    const [isWeekSelectionActive, setIsWeekSelectionActive] = useState<boolean>(true);
    const [updateFormat] = useUpdateBookingFormatMutation();

    const switchCalendarType = (calendarTypeValue: number) => {
        var newType = calendarTypeValue === 0 ? DateRangeType.Week : DateRangeType.Month;
        if (newType != dateRangeType) {
            updateFormat(calendarTypeValue);
            dispatch(updateDateRangeType(calendarTypeValue === 0 ? DateRangeType.Week : DateRangeType.Month));
            setIsWeekSelectionActive(!isWeekSelectionActive);
        }
    }

    useEffect(() => {
        setIsWeekSelectionActive(dateRangeType == DateRangeType.Week);
    }, [dateRangeType])

    return (<div className='hidden lg:flex flex-row space-x-1 w-full justify-around'>
        <CalendarFormatButton icon='CalendarWorkWeek' buttonText='App_Button_Calendar_Week' calendarTypeValue={0} isActive={isWeekSelectionActive} callbak={switchCalendarType} />
        <CalendarFormatButton icon='Calendar' buttonText='App_Button_Calendar_Month' calendarTypeValue={1} isActive={!isWeekSelectionActive} callbak={switchCalendarType} />
    </div>)
}