import { useEffect, useRef } from 'react';

export const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback]);

    return ref;
};

interface CursorFxOptions {
    target: HTMLElement;
    objects: Array<{ element: HTMLElement; effect?: string; delta?: string; direction?: string }>;
}

class CursorFx {
    target: HTMLElement;
    objects: Array<{ element: HTMLElement; effect?: string; delta?: string; direction?: string }>;
    animating: boolean;
    animatingId: number | false;
    rotateValue: Array<number | false>;

    constructor(opts: CursorFxOptions) {
        this.target = opts.target;
        this.objects = opts.objects;
        this.animating = false;
        this.animatingId = false;
        this.rotateValue = [];
        this.initCursorFx();
    }

    private initCursorFx() {
        // detect mouse move on card element
        this.target.addEventListener('mousemove', (event: MouseEvent) => {
            if (this.animating) return;
            this.animating = true;
            this.animatingId = window.requestAnimationFrame(this.moveObjs.bind(this, event));
        });
    }

    private moveObjs(event: MouseEvent) {
        // update target size info
        const targetInfo = this.target.getBoundingClientRect();
        for (let i = 0; i < this.objects.length; i++) {
            if (!this.rotateValue[i]) this.rotateValue[i] = false;
            this.moveSingleObj(this.objects[i], event, i);
        }
        this.animating = false;
    }

    private moveSingleObj(objDetails: { element: HTMLElement; effect?: string }, event: MouseEvent, index: number) {
        let effect = 'parallax';
        if (objDetails.effect) effect = objDetails.effect;

        if (effect === 'parallax') {
            this.moveObjParallax(objDetails, event);
        } else if (effect === 'follow') {
            this.moveObjFollow(objDetails, event);
        }
    }

    private moveObjParallax(objDetails: { element: HTMLElement; delta?: string; direction?: string }, event: MouseEvent) {
        // get translateX and translateY values
        const deltaTranslate = parseInt(objDetails.delta || '0');
        const targetInfo = this.target.getBoundingClientRect();
        let translateX = (2 * deltaTranslate / targetInfo.width) * (targetInfo.left + targetInfo.width / 2 - event.clientX);
        let translateY = (2 * deltaTranslate / targetInfo.height) * (targetInfo.top + targetInfo.height / 2 - event.clientY);
        // check if we need to change direction
        if (objDetails.direction && objDetails.direction === 'follow') {
            translateX = -1 * translateX;
            translateY = -1 * translateY;
        }

        objDetails.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
    }

    private moveObjFollow(objDetails: { element: HTMLElement }, event: MouseEvent) {
        const objInfo = objDetails.element.getBoundingClientRect();
        objDetails.element.style.transform = `translateX(${(event.clientX - objInfo.width / 2)}px) translateY(${(event.clientY - objInfo.height / 2)}px)`;
    }
}

export const useParallaxMouse = (fxElements: any[]) => {

    useEffect(() => {
        const cursorFx: HTMLCollectionOf<Element> = document.getElementsByClassName('cursor-fx-target');
        if (cursorFx.length > 0) {
            new CursorFx({
                target: cursorFx[0] as HTMLElement,
                objects: fxElements
              });
        }
    

        return () => {};
    }, [fxElements]);
}