import { useState } from 'react'
import { useAppDispatch, useAppSelector, setCalendarStrings } from '../../Redux'
import { NavigationButton } from '../Buttons/NavigationButton';
import { MaintenancePanelButton } from '../Buttons/MaintenancePanelButton';
import { ShowHiddenProjectsButton } from '../Buttons/ShowHiddenProjectsButton';
import { ChangelogNavigationButton } from '../Changelog/ChangelogNavigationButton';
import { switchNavigationPanel } from '../../Redux/userProfileSlice';
import { SignOutButton } from '../Buttons/SignOutButton';
import { UserDetailsPersona } from '../UserDetails/UserDetailsComponent';
import { useOutsideClick } from '../../Utils';
import { UserSettingsButton } from '../Buttons/UserSettingsButton';
import { LanguageSwitch } from '../Language/LanguageComponent';

export const Navigation = () => {
    const dispatch = useAppDispatch();

    const isError: boolean = useAppSelector(s => s.administration.isError);
    const isMaintainance: boolean = useAppSelector(s => s.administration.isMaintainance);
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
    const hiddenProjectsExist = useAppSelector((state) => state.projects.hiddenProjectsExist);
    const bookingComments = useAppSelector((state) => state.activityTemplates.activityTemplatesList);

    const [sidebarOpen, setSidebarOpen] = useState(isNavigationPanelOpen);
    const [showSignOutCallout, setShowSignOutCallout] = useState<boolean>(false);

    const signOutContainerRefDiv = useOutsideClick(() => {
        setShowSignOutCallout(false);
    });

    const onSidebarMenuClick = () => {
        setSidebarOpen(!sidebarOpen);
        dispatch(switchNavigationPanel());
    }

    return (
        <div className={`hidden lg:fixed lg:z-[100] lg:inset-y-0 lg:left-0 lg:block lg:overflow-y-auto lg:bg-tertiary lg:pb-4 ${sidebarOpen ? 'lg:w-64' : 'lg:w-16'} shadow-lg transition-all`}>
            {
                !isMaintainance && !isError && userProfile?.id &&
                <>
                    <nav className="mt-36">
                        <ul role="list" className="flex flex-col items-start flex-wrap content-around justify-center space-y-1">
                            <li>
                                <NavigationButton buttonText='Home' icon='Home' navigationPath='/dashboard' small={!sidebarOpen} />
                            </li>
                            <li>
                                <NavigationButton buttonText='App_Button_Activity_Templates' icon='BulletedList' navigationPath='/activityTemplates' showCoachMark={bookingComments.length < 1} small={!sidebarOpen} />
                            </li>
                            <li>
                                <NavigationButton buttonText='App_Button_Download_Recording' icon='DownloadDocument' navigationPath='/download' small={!sidebarOpen} />
                            </li>
                            {hiddenProjectsExist && <li><ShowHiddenProjectsButton /></li>}
                            <li>
                                <ChangelogNavigationButton small={!sidebarOpen} />
                            </li>
                            {userProfile.isAdmin && <li><MaintenancePanelButton small={!sidebarOpen} /></li>}
                            <li>
                                <NavigationButton
                                    openInNewWindow
                                    buttonText='App_Button_Help'
                                    icon='Help'
                                    navigationPath={userProfile?.preferredLanguage === 1031 ? process.env.REACT_APP_HELP_URL_DE : process.env.REACT_APP_HELP_URL_EN}
                                    small={!sidebarOpen} />
                            </li>
                        </ul>
                    </nav>
                    <div className={`fixed bottom-28 flex ${sidebarOpen ? 'lg:w-64 justify-start pl-8' : 'lg:w-16 justify-center'}`}>
                        <LanguageSwitch />
                    </div>
                    {
                        userProfile && <div ref={signOutContainerRefDiv} onClick={() => setShowSignOutCallout(!showSignOutCallout)} className={`fixed bottom-14 flex justify-center ${sidebarOpen ? 'lg:w-64' : 'lg:w-16'}`} >
                            <UserDetailsPersona noDetails={!sidebarOpen} />
                            <div className={`fixed bottom-16 flex-grow px-5 rounded-md shadow-md bg-white z-[121] hover:ring-1 ring-primary/70 animate-fade-in
                                            ${showSignOutCallout ? 'flex' : 'hidden'} 
                                            ${sidebarOpen ? 'left-64' : 'left-14 '} `}>
                                <div className="flex flex-col w-full">
                                    <UserSettingsButton />
                                    <SignOutButton />
                                </div>
                            </div>
                        </div>
                    }
                    <div className={`group fixed bottom-0 h-12 pl-5 flex items-center bg-gradient-to-r from-secondary/50 to-coral/50 
                                    ${sidebarOpen ? 'lg:w-64 justify-end pr-4' : 'lg:w-16'}`} onClick={onSidebarMenuClick}>
                        {
                            !sidebarOpen ?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-primary group-hover:animate-open-shake">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-primary group-hover:animate-close-shake">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
                                </svg>
                        }
                    </div>
                </>
            }
        </div>
    );
}