import { Icon } from "@fluentui/react"
import { useEffect, useState } from "react"
import { Translate } from "react-i18nify"

interface IInfoBubbleProps {
    showBubble: boolean,
    title: string,
    text: string,
    onCloseCallback: Function
}

export const InfoBubble = (props: IInfoBubbleProps) => {
    const {showBubble, title, text, onCloseCallback} = {...props};
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isTextVisible, setIsTextVisible] = useState<boolean>(false);

    useEffect(() => { setIsVisible(showBubble) }, [showBubble])

    return (<>
        {isVisible &&
            <span className="relative flex h-5 w-5 items-center" onMouseOver={() => setIsTextVisible(true)}>
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
            </span>
        }
        {
            isVisible && isTextVisible && <div className="bg-primary shadow-sm text-tertiary w-58 h-auto z-[101] flex flex-col space-y-1 p-4">
                <span className="flex flex-row items-center justify-between">
                    <h4 className="font-semibold text-xl"><Translate value={title} /></h4>
                    <Icon iconName="ChromeClose" className="cursor-pointer" onClick={() => onCloseCallback()} />
                </span>
                <span><Translate value={text} /></span>
            </div>
        }
    </>)
}