import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IUserProfile } from '../Models/IUserProfile'

interface UserProfileState {
    userProfile?: IUserProfile,
    isDateIncrementationEnabled: boolean,
    showUserSettingsPanel: boolean,
    isNavigationPanelOpen: boolean
}

const initialState: UserProfileState = {
    userProfile: undefined,
    isDateIncrementationEnabled: true,
    showUserSettingsPanel: false,
    isNavigationPanelOpen: false
}

export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<IUserProfile | undefined>) => {
            state.userProfile = action.payload;
        },
        setHasReadChangelog: (state, action: PayloadAction<boolean>) => {
            if (state.userProfile) {
                state.userProfile.hasSeenChangelog = action.payload
            }
        },
        setDayIncrementationPreference: (state, action: PayloadAction<boolean>) => {
            state.isDateIncrementationEnabled = action.payload;
        },        
        switchShowUserSettings: (state) => {
            state.showUserSettingsPanel = !state.showUserSettingsPanel;
        },
        switchNavigationPanel: (state) => {
            state.isNavigationPanelOpen = !state.isNavigationPanelOpen;
        }
    }
});

export const userProfileApi = createApi({
    reducerPath: 'user_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/userprofile`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchUserProfile: builder.query<IUserProfile, void>({
                query() {
                    return "/GetUserProfile";
                }
            }),
            updateUserLanguage: builder.mutation({
                query: lcid => ({
                    url: `/SwitchLanguage?language=${lcid}`,
                    method: 'PUT'
                })
            }),
            updateHasReadChangelog: builder.mutation({
                query: value => ({
                    url: `/SwitchHasReadChangelog?hasReadChangelog=${value}`,
                    method: 'PUT'
                })
            }),
            updateBookingFormat: builder.mutation({
                query: format => ({
                    url: `/SwitchBookingFormat?bookingFormat=${format}`,
                    method: 'PUT'
                })
            }),
            updateDateIncrementationPreference: builder.mutation({
                query: preferenceValue => ({
                    url: `/SwitchDateIncrementationPreference?preferenceValue=${preferenceValue}`,
                    method: 'PUT'
                })
            }),
            putUserProfile: builder.mutation({
                query: userProfile => ({
                    url: `/PutUserProfile`,
                    method: 'PUT',
                    body: userProfile
                })
            }),
            updateCountry: builder.mutation({
                query: countryId => ({
                    url: `/SwitchCountry?countryId=${countryId}`,
                    method: 'PUT'
                })
            }),
        }
    }
});

export const {
    useFetchUserProfileQuery,
    useLazyFetchUserProfileQuery,
    useUpdateUserLanguageMutation,
    useUpdateBookingFormatMutation,
    useUpdateHasReadChangelogMutation,
    useUpdateDateIncrementationPreferenceMutation,
    useUpdateCountryMutation,
    usePutUserProfileMutation } = userProfileApi;

export const { setUserProfile, setHasReadChangelog, setDayIncrementationPreference, switchShowUserSettings, switchNavigationPanel } = userProfileSlice.actions;

export default userProfileSlice.reducer;