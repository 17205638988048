import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IUserProject } from '../Models/IUserProject';

interface IProjectsState {
    projectsList: IUserProject[],
    overwriteHiddenProperty: boolean,
    hiddenProjectsExist: boolean,
    currentSelectedProject?: IUserProject,
    showProjectEditPanel: boolean,
}

const initialState: IProjectsState = {
    projectsList: [],
    overwriteHiddenProperty: false,
    hiddenProjectsExist: false,
    currentSelectedProject: undefined,
    showProjectEditPanel: false
}

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setCurrentProjects: (state, action: PayloadAction<IUserProject[]>) => {
            state.projectsList = action.payload;
        },
        switchShowProjectEditPanel: (state) => {
            state.currentSelectedProject = undefined;
            state.showProjectEditPanel = !state.showProjectEditPanel;
        },
        openProjectEditPanel: (state, action: PayloadAction<IUserProject>) => {
            state.currentSelectedProject = action.payload;
            state.showProjectEditPanel = true;
        },
        updateProjectName: (state, action: PayloadAction<{id: number, newName: string }>) => {
            state.projectsList = state.projectsList.map(obj => {
                if (obj.id === action.payload.id) {
                    return { ...obj, displayTitle: action.payload.newName };
                }

                return obj;
            });
        },
        updateProject: (state, action: PayloadAction<{id: number, updatedProject: IUserProject }>) => {
            state.projectsList = state.projectsList.map(obj => {
                if (obj.id === action.payload.id) {
                    return { ...action.payload.updatedProject };
                }

                return obj;
            });
        },
        updateProjectGroup: (state, action: PayloadAction<{id: number, groupId: number | null }>) => {
            state.projectsList = state.projectsList.map(obj => {
                if (obj.id === action.payload.id) {
                    return { ...obj, projectGroup_Id: action.payload.groupId };
                }

                return obj;
            });
        },
        updateProjectVisibility: (state, action: PayloadAction<{id: number, isHidden: boolean }>) => {
            state.projectsList = state.projectsList.map(obj => {
                if (obj.id === action.payload.id) {
                    return { ...obj, isHidden: action.payload.isHidden };
                }

                return obj;
            });
        },
        switchOverwriteHiddenProperty: (state) => {
            state.overwriteHiddenProperty = !state.overwriteHiddenProperty;
        },
        setHiddenProjectsExist: (state) => {
            state.hiddenProjectsExist = state.projectsList.filter(p => p.isHidden).length > 0;
        }
    }
});

export const userProjectsApi = createApi({
    reducerPath: 'projects_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/userproject`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    tagTypes: ['ProjectsList'],
    endpoints(builder) {
        return {
            fetchProjects: builder.query<IUserProject[], Date[]>({
                query(dateRange) {
                    var startDate = dateRange[0].toDateString();
                    var endDate = dateRange[1].toDateString();
                    return `/GetUserProjects?startDate=${startDate}&endDate=${endDate}`;
                },
                providesTags: ['ProjectsList']
            }),
            fetchProject: builder.query<IUserProject, { id: string }>({
                query(params) {
                    return `/GetUserProject?id=${params.id}`;
                },
                providesTags: ['ProjectsList']
            }),
            fetchProjectsByGroupId: builder.query<IUserProject[], { groupId: number | undefined, dateRange: Date[] }>({
                query(props) {
                    var startDate = props.dateRange[0].toDateString();
                    var endDate = props.dateRange[1].toDateString();
                    return `/GetUserProjectsByGroupId?groupId=${props.groupId}&startDate=${startDate}&endDate=${endDate}`;
                },
                providesTags: ['ProjectsList']
            }),
            postUserProject: builder.mutation({
                query: project => ({
                    url: `/PostUserProject`,
                    method: 'POST',
                    body: project
                }),
                invalidatesTags: ['ProjectsList']
            }),
            putUserProject: builder.mutation({
                query: project => ({
                    url: `/PutUserProject?id=${project.id}`,
                    method: 'PUT',
                    body: project
                }),
                invalidatesTags: ['ProjectsList']
            }),
            deleteUserProject: builder.mutation({
                query: projectId => ({
                    url: `/DeleteUserProject?id=${projectId}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['ProjectsList']
            })
        }
    }
});


export const { useFetchProjectsQuery, useLazyFetchProjectsQuery, useLazyFetchProjectQuery, usePutUserProjectMutation, usePostUserProjectMutation, useDeleteUserProjectMutation, useLazyFetchProjectsByGroupIdQuery } = userProjectsApi;

export const { setCurrentProjects, switchShowProjectEditPanel, openProjectEditPanel, updateProjectName, updateProject, updateProjectGroup, updateProjectVisibility, switchOverwriteHiddenProperty, setHiddenProjectsExist } = projectsSlice.actions;

export default projectsSlice.reducer;