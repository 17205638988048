import { NavigationButton } from "../Buttons/NavigationButton"
import { setHasReadChangelog, useAppDispatch, useAppSelector, useUpdateHasReadChangelogMutation } from "../../Redux";
import { useEffect } from "react";
import { useBoolean } from '@fluentui/react-hooks';

interface IChangelogNavigationButtonProps {
    small?: boolean
}

export const ChangelogNavigationButton = (props: IChangelogNavigationButtonProps) => {
    const { small } = { ...props };

    const dispatch = useAppDispatch();
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);
    const [isCoachmarkVisible, { setFalse: hideCoachmark, setTrue: showCoachmark }] = useBoolean(false);
    const [updateHasReadChangelog] = useUpdateHasReadChangelogMutation();

    useEffect(() => {
        if (userProfile?.id && !userProfile.hasSeenChangelog) {
            showCoachmark();
        }
    }, [userProfile]);

    const handleChangelogClick = () => {
        if (!userProfile?.hasSeenChangelog) {
            updateHasReadChangelog(true);
            dispatch(setHasReadChangelog(true));
            hideCoachmark();
        }
    }

    return (<>
        <NavigationButton buttonText='App_Button_Changelog' icon='History' navigationPath='/changelog' callback={handleChangelogClick} showCoachMark={isCoachmarkVisible} small={small} />
    </>)
}