import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon, ArrowUturnLeftIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReactElement } from 'react'
import { Translate } from 'react-i18nify'

interface IFaildeTimeEntryActionsButton {
    showRevert: boolean,
    editCallback: Function,
    deleteCallback: Function,
    retryCallback: Function,
    revertCallback: Function
}

export const FailedTimeEntryActions = (props: IFaildeTimeEntryActionsButton) => {
    const { showRevert, editCallback, deleteCallback, retryCallback, revertCallback } = { ...props }

    const items: { name: string, callback: Function, icon?: ReactElement }[] = [
        { name: 'App_Button_Edit', callback: editCallback, icon: <PencilIcon className='stroke-1 stroke-primary h-4 w-5' /> },
        { name: 'App_Button_Refresh', callback: retryCallback, icon: <ArrowPathIcon className='cursor-pointer stroke-1 stroke-primary h-4 w-5' /> },
        { name: 'App_Button_Delete', callback: deleteCallback, icon: <TrashIcon className='stroke-1 stroke-primary h-4 w-5' /> },
    ]

    return (
        <div className="lg:inline-flex rounded-md shadow-sm mt-2 lg:mt-0">
            <div className='flex flex-row lg:inline-flex lg:flex-row w-28'>
                <button type="button" className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-primary ring-1 ring-inset ring-primary hover:bg-primary/10 focus:z-10">
                    Actions
                </button>
                <Menu as="div" className="relative -ml-px block">
                    <Menu.Button className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-primary ring-1 ring-inset ring-primary hover:bg-primary/10 focus:z-10">
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                        <div className="py-1 flex flex-col">
                            {items.map((item) => (
                                <Menu.Item key={item.name}>
                                    <div className="cursor-pointer inline-flex px-4 py-2 text-sm text-primary hover:bg-primary/10 data-[focus]:bg-primary/10 space-x-2" onClick={() => item.callback()}>{item.icon} <span className='ml-2'><Translate value={item.name} /></span></div>
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Menu>
            </div>
            {
                showRevert &&
                <div className="cursor-pointer w-28 bg-white font-semibold inline-flex items-center justify-center rounded-md mt-2 ml-0 lg:mt-0 lg:ml-2 px-2 py-2 text-primary ring-1 ring-inset ring-primary hover:bg-primary/10 focus:z-10"
                    onClick={() => revertCallback()}>
                    <span className='inline-flex space-x-2'><ArrowUturnLeftIcon className='stroke-1 stroke-primary h-4 w-5' /> <Translate value='App_Button_Revert' /></span>
                </div>
            }
        </div>
    )
}
