import { Translate } from "react-i18nify"
import { switchShowMaintenancePanel, useAppDispatch } from "../../Redux"
import { Icon } from "@fluentui/react/lib/components/Icon"
import { NavigationButton } from "./NavigationButton"

interface IMaintenancePanelButton {
    small?: boolean
}

export const MaintenancePanelButton = (props: IMaintenancePanelButton) => {
    const { small } = { ...props };

    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(switchShowMaintenancePanel());
    }

    return (<>
        <NavigationButton buttonText='App_Button_Maintenance' icon='CalendarSettings' isActionButton callback={onClick} small={small} />
    </>)
}